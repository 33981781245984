/* FONTS
font-family: 'Open Sans', sans-serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Roboto', sans-serif;
font-family: 'Roboto Slab', serif;
font-family: 'PT Sans', sans-serif;
font-family: 'Source Sans Pro', sans-serif;
font-family: 'DM Sans', sans-serif;
font-family: 'Ubuntu', sans-serif;
font-family: 'Rubik', sans-serif; */

@import url(fontawesome-all.min.css);

@-ms-viewport {
	width: device-width;
}

body {
	-ms-overflow-style: scrollbar;
}

@media screen and (max-width: 480px) {

	html, body {
		min-width: 320px;
	}

}

html {
  box-sizing: border-box;
}

body {
  padding-top: 60px;
  /* background-image: url("../images/background.jpg"); */
  font-family: "Open Sans", sans-serif;
  background-color: #be2623;
  background-size: auto, cover;
  background-attachment: fixed, fixed;
  background-position: center, center;
  vertical-align: baseline;
}

article {
  display: block;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1 {
  font-size: 22px;
  font-weight: 600;
  color: #be2623;
  margin: 0 0 16px;
}

h2 {
  color: #be2623;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
}

p {
  color: #be2623;
  font-size: 14px;
}

p.major {
  color: #eae8dc;
  font-size: 16px;
  margin: 0 0 2em 0;
}

hr {
  width: 100%;
  height: 2px;
  background: #be2623;
}

h1.major,
h2.major,
h3.major,
h4.major,
h5.major,
h6.major {
  padding-bottom: 1em;
  color: #eae8dc;
  border-bottom: solid 2px #eae8dc;
}

ol,
ul {
  list-style: none;
}

.parent {
  background-color: transparent;
  border-color: transparent;
}

.parent .navbar {
  background-color: #eae8dc;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 9999;
  /* display: none; */
  transition: top 0.6s;
}

.parent .navbar-nav li {
  /* display: inline-block; */
  background-color: #eae8dc;
}

.parent .navbar-nav li a {
  display: block;
  padding: 15px;
  text-decoration: none;
  color: #be2623;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 10px;
}

.parent .navbar-nav li a,
.parent .navbar-nav li a:after,
.parent .navbar-nav li a:before {
  transition: all 0.5s;
}

.parent .navbar-brand {
  font-weight: 700;
  color: #be2623;
}

.parent .navbar-brand:hover {
  color: #be2623;
}

.parent .navbar-nav li a {
  position: relative;
}

.parent .navbar-nav li a:hover {
  color: #be2623;
}

.parent .navbar-nav li a:after {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 0%;
  content: ".";
  color: transparent;
  background: #be2623;
  height: 1px;
}

.parent .navbar-nav li a:hover:after {
  width: 100%;
}

/* animation for NavItem - circle fill */
/* nav.circle ul li a {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

nav.circle ul li a:after {
  display: block;
  position: absolute;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: '.';
  color: transparent;
  width: 1px;
  height: 1px;
  border-radius: 50%;
  background: transparent;
}

nav.circle ul li a:hover:after {
  -webkit-animation: circle 1.5s ease-in forwards;
} */

/* Keyframes */
/*@-webkit-keyframes circle {
	0% {
	  width: 1px;
	  top: 0;
	  left: 0;
	  bottom: 0;
	  right: 0;
	  margin: auto;
	  height: 1px;
	  z-index: -1;
	  background: #eee;
	  border-radius: 100%;
	}
	100% {
	  background: #aaa;
	  height: 5000%;
	  width: 5000%;
	  z-index: -1;
	  top: 0;
	  bottom: 0;
	  left: 0;
	  right: 0;
	  margin: auto;
	  border-radius: 0;
	}
  } */

.wrapper {
  background-color: #eae8dc;
  border-radius: 40px;
  padding-left: 8em;
  padding-right: 8em;
  margin: 8em;
}

@media screen and (max-width: 1268px) {
  body {
    padding-top: 50px;
  }

  .parent {
    margin: 2em;
  }

  .wrapper {
    padding-left: 6em;
    padding-right: 6em;
    margin: 4em;
  }
}

@media screen and (max-width: 960px) {
  body {
    padding-top: 40px;
  }

  .parent {
    margin: 2em;
  }

  .wrapper {
    padding-left: 2em;
    padding-right: 2em;
    margin: 2em;
  }
}

@media screen and (max-width: 768px) {
  .parent {
    margin: 2em;
  }

  .wrapper {
    padding-left: 2em;
    padding-right: 2em;
  }
}

@media screen and (max-width: 480px) {
  body {
    padding-top: 20px;
  }

  .parent {
    margin: 0.5em;
  }

  .wrapper {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}

.home,
.aboutus {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  margin-top: 5em;
  margin-bottom: 10em;
  width: 100%;
}

.home .left h1 {
  font-size: 28px;
  font-weight: 600;
  color: #be2623;
}

.aboutus .right h2 {
  font-size: 22px;
  font-weight: 600;
  color: #be2623;
}

.home .left p,
.aboutus .right p {
  color: #be2623;
  font-size: 18px;
  padding: 1em 0 0 0;
}

.home .left {
  margin: 1%;
  padding: 5em 0em 0em 2em;
}

.aboutus .right {
  margin: 1%;
  padding: 2em 1em 0em 1em;
  width: calc(50% - 1.5em);
}

.aboutus .left {
  width: calc(50% - 1.5em);
}

.home .right,
.aboutus .left {
  margin: 1%;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media screen and (max-width: 736px) {
  .home {
    flex-direction: column-reverse;
  }

  .aboutus {
    flex-direction: column;
  }

  .home,
  .aboutus {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 3em;
  }

  .home .left,
  .aboutus .right {
    padding: 1em;
    margin: 0.875em 0em 0.875em 0;
    text-align: center;
    width: 100%;
  }

  .home .right,
  .aboutus .left {
    margin: 0.875em 0em 0.875em 0;
    width: 100%;
  }

  .home .left p,
  .aboutus .right p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .home {
    flex-direction: column-reverse;
  }

  .aboutus {
    flex-direction: column;
  }

  .home,
  .aboutus {
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2em;
  }

  .home .left,
  .aboutus .right {
    padding: 1em;
    margin: 0 0 2em 0 !important;
    text-align: center;
  }

  .home .right,
  .aboutus .left {
    margin: 0 0 2em 0 !important;
  }
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  width: 100%;
  height: 100%;
  /* pointer-events: none; */
}

.App-about {
  width: 100%;
  height: 100%;
  border-radius: 40px;
}

.page-separator {
  width: 30px;
  height: 30px;
  display: block;
  margin: 0 auto;
  margin-bottom: 1em;
}

.about h1,
#footer h1 {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  color: #be2623;
  margin: 0 auto;
}

.about h2 {
  color: #be2623;
  font-weight: bold;
  font-size: 20px;
  margin: 0 0 8px;
}

.features {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 0 4em 0;
}

.features article {
  padding: 1.75em 1.75em 2em 1.75em;
  background-color: #be2623;
  border-radius: 30px;
  margin: 1.5em 3em 1.5em 0;
  width: calc(50% - 1.5em);
}

.features article:nth-child(2n) {
  margin-right: 0;
}

.features article .image {
  border-radius: 10px 5px 0 0;
  display: block;
  margin-bottom: 1.75em;
  margin-left: -1.75em;
  margin-top: -1.75em;
  position: relative;
  width: calc(100% + 3.5em);
}

.features article .image img {
  border-radius: 30px 30px 0 0;
  width: 100%;
}

@media screen and (max-width: 980px) {
  .features article {
    margin: 1em 2em 1em 0;
    width: calc(50% - 1em);
  }
}

@media screen and (max-width: 736px) {
  .features {
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .features article {
    padding: 1.5em 1.5em 0.1em 1.5em;
    margin: 0.875em 1.75em 0.875em 0;
    /* width: calc(50% - 0.875em - 1px); */
    width: 100%;
  }

  .features article h3.major {
    font-size: 14px;
  }

  .features article p.major {
    font-size: 12px;
  }

  .features article .image {
    margin-bottom: 1.5em;
    margin-left: -1.5em;
    margin-top: -1.5em;
    width: calc(100% + 3em);
  }
}

@media screen and (max-width: 480px) {
  .features {
    display: block;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .features article {
    width: 100%;
    margin: 0 0 2em 0 !important;
  }

  .features article h3.major {
    font-size: 12px;
  }

  .features article p.major {
    font-size: 10px;
  }
}

/* Icon */

.icon {
  text-decoration: none;
  border-bottom: none;
  position: relative;
}

.icon:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-weight: 400;
}

.icon > .label {
  display: none;
}

.icon:before {
  line-height: inherit;
}

.icon.solid:before {
  font-weight: 900;
}

.icon.brands:before {
  font-family: "Font Awesome 5 Brands";
}

/* Contact */

ul.contact {
  list-style: none;
  padding: 0;
}

ul.contact li {
  text-decoration: none;
  margin: 2.5em 0 0 0;
  padding: 0 0 0 3.25em;
  position: relative;
}

ul.contact li:before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  text-transform: none !important;
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}

ul.contact li:before {
  border-radius: 100%;
  border: solid 2px #be2623;
  display: inline-block;
  font-size: 0.8em;
  height: 2.5em;
  left: 0;
  line-height: 2.35em;
  position: absolute;
  text-align: center;
  top: 0;
  width: 2.5em;
  margin-top: -2px;
}

ul.contact li:first-child {
  margin-top: 0;
}

@media screen and (max-width: 736px) {
  ul.contact li {
    margin: 1.5em 0 0 0;
  }
}

/* Footer */

#footer .inner {
  padding: 5em 0 3em 0;
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-direction: row;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 auto;
}

#footer .inner > * {
  width: 100%;
}

#footer .inner .contact {
  width: calc(50% - 1.5em);
}

#footer .inner {
  list-style: none;
  width: 100%;
}

#footer .inner li {
  color: #be2623;
  margin-top: 24px;
  padding-left: 52px;
}

/* #footer .inner li:first-child {
					border-left: 0;
					margin-left: 0;
					padding-left: 0;
				} */

#footer .inner li a {
  color: inherit;
  border-bottom: dotted 1px #be2623;
}

@media screen and (max-width: 1280px) {
  #footer {
    background-color: #eae8dc;
    background-size: auto, cover;
    background-position: center, center;
    margin-top: -6.5em;
    padding-top: 6.5em;
  }
}

@media screen and (max-width: 980px) {
  #footer {
    margin-top: -4.75em;
    padding-top: 4.75em;
  }

  #footer .inner {
    padding: 3em 3em 1em 3em;
    display: block;
    width: 100%;
  }

  #footer .inner .contact {
    width: 100%;
    margin: 0 0 4em 0;
  }

  #footer .inner {
    margin: 0em 0 2em 0;
  }
}

@media screen and (max-width: 736px) {
  #footer {
    margin-top: -2.5em;
    padding-top: 2.5em;
  }

  #footer .inner {
    padding: 2em 0em 0.5em 0em;
  }

  #footer .inner .contact {
    margin: 0 0 3em 0;
  }
}

@media screen and (max-width: 480px) {
  #footer .inner {
    padding: 2em 0em 0.5em 0em;
  }

  #footer .inner .copyright li {
    border-left: 0;
    display: block;
    margin: 1em 0 0 0;
    padding-left: 0;
  }

  #footer .inner li:first-child {
    margin-top: 0;
  }
}

@media screen and (max-width: 360px) {
  #footer .inner {
    padding: 2em 1.5em 2em 1.5em;
  }
}

.copyright {
  text-align: center;
  bottom: 0;
  margin: 0 auto;
  padding-bottom: 2em;
}

.copyright p {
  color: #be2623;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
}
